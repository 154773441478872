<template>
  <v-row class="app-preview fill-height">
    <v-col
      cols="12"
      lg="6"
      class="mx-auto my-lg-auto"
    >
      <template>
        <v-card
          v-if="invoice"
          class="printable--theme mb-3 pt-5"
        >
          <div class="pb-2 px-5">
            <!-- Letterhead -->
            <Letterhead />

            <v-row class="pt-2">
              <!-- Date -->
              <v-col
                cols="8"
                class="d-flex align-center"
              >
                <p class="me-2 mb-0 printable-text font-weight-medium">
                  Date Issued: {{ invoice.created_at }}
                </p>
              </v-col>

              <!-- Invoice number -->
              <v-col
                cols="4"
                class="d-flex align-center"
              >
                <p class="me-2 mb-0 printable-text font-weight-medium">
                  Official Invoice No:
                  <span class="error--text">{{ invoice.invoice_number }}</span>
                </p>
              </v-col>
            </v-row>
          </div>

          <v-divider class="printable-divider"></v-divider>

          <div class="py-2 px-5">
            <v-row>
              <!-- Customer Details -->
              <v-col cols="8">
                <p class="font-weight-semibold payment-details-header mb-1">
                  Invoice To:
                </p>
                <p class="mb-0">
                  {{ invoice.customer_name }}
                </p>
                <template v-if="invoice.insurance_product.name === 'Motor insurance'">
                  <p class="mb-0">
                    Car registration: {{ invoice.car_registration_number }}
                  </p>
                </template>
                <p class="mb-0">
                  Insurance: {{ invoice.insurance.name }}
                </p>
                <p class="mb-0">
                  Sum insured: {{ invoice.sum_insured }}
                </p>
                <template v-if="invoice.general_remarks !== null">
                  <p class="mb-0">
                    Remarks: {{ invoice.general_remarks }}
                  </p>
                </template>
              </v-col>

              <!-- Payment method -->
              <v-col>
                <p class="font-weight-semibold payment-details-header mb-1">
                  Payment By:
                </p>
                <p>
                  {{ invoice.payment_method.name }}
                </p>
                <div class="d-block d-sm-none">
                  <qrcode-vue
                    :value="invoice.record_preview"
                    level="L"
                  />
                  <p>Scan for authenticity</p>
                </div>
              </v-col>
              <v-col
                align="center"
                class="mt-1 d-none d-sm-block"
              >
                <qrcode-vue
                  :value="invoice.record_preview"
                  level="L"
                />
                <p>Scan for authenticity</p>
              </v-col>
            </v-row>
          </div>

          <v-divider class="printable-divider"></v-divider>

          <Details :record="invoice" />

          <v-divider class="printable-divider"></v-divider>

          <Signatures />

          <template v-if="$vuetify.breakpoint.smAndUp">
            <v-divider class="printable-divider"></v-divider>
            <Branches />
          </template>
        </v-card>
      </template>
    </v-col>
    <div></div>
  </v-row>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { Letterhead, Details, Signatures, Branches } from '@/components/apps/previews'

export default {
  name: 'invoice-preview',
  components: {
    Letterhead,
    Details,
    Signatures,
    Branches,
    QrcodeVue,
  },
  data: () => ({
    invoice: null,
  }),
  mounted() {
    this.fetchInvoice()
  },
  methods: {
    fetchInvoice() {
      this.$store
        .dispatch('fetchRecord', { record: 'invoices', preview_token: this.$route.params.preview_token })
        .then(response => {
          this.invoice = response.data.data
          this.onbeforeprint()
          this.onafterprint()
        })
        .catch((error) => {
          this.$router.push({name: 'Home', params: { message: error.response.data.message }})
        })
    },
    onbeforeprint() {
      window.addEventListener('beforeprint', (event) => {
        document.title = `invoice-${this.invoice.invoice_number}`
      })
    },
    onafterprint() {
      window.addEventListener('afterprint', (event) => {
        document.title = 'Niagamas Insurance Services Sdn. Bhd.'
      })
    }
  },
}  
</script>

<style lang="scss">
@page {
  size: A4;
  margin: 0mm;
}

.app-preview {
  .payment-details-header {
    margin-bottom: 0.813rem;
  }
  .v-card.printable--theme {
    background-color: #fff !important;

    p {
      color: rgba(94, 86, 105, 0.87) !important;
    }

    .printable-divider {
      border-color: rgba(94, 86, 105, 0.14) !important;
    }
  }
  .purchased-items-table {
    th {
      background-color: transparent !important;
    }
  }
  .select-to {
    width: 14rem;
  }
  .printable--theme {
    p {
      font-size: 9px;
    }
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .app-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-preview {
    .printable--theme {
      border-radius: 0px;
    }

    .cut-line {
      border-bottom: 1px red dashed;
    }
  }

  .app-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
